import { FormControl, Select, MenuItem, InputLabel, Button, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { API } from '../../../api';
import videoStore from '../../../stores/videoStore';
import styles from './VideoActionSelect.module.scss';
import { saveAs } from 'file-saver';
import moment from 'moment';
import JSZip from 'jszip';
import settingsStore from '../../../stores/settingsStore';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import { getVideoGeojson } from '../../../logic/get-video-geojson';
import authStore from '../../../stores/authStore';

interface Props {}

const VideoActionSelect = (props: Props) => {
  const [rows, setRows] = useState<string[]>([]);
  const { t } = useTranslation();
  const { selectedVideos } = videoStore;
  const { adminRole } = authStore;
  const [selectedAction, setSelectedAction] = useState<string>('');

  const showGPX = useCallback(async (videoId: number) => {
    const response = await API.getGPX(videoId);
    if (response) {
      let blob = new Blob([response], { type: 'text/xml' });
      saveAs(blob, `GPX-${videoId}.gpx`);
      /*  let url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url); */

      /* var win = window.open();
      if (win) {
        win.document.write('<iframe src="data:text/xml,'+encodeURIComponent(response) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
      } */
    }
  }, []);

  const getGPX = useCallback(async (videoId: number): Promise<Blob | null> => {
    const response = await API.getGPX(videoId);
    if (response) {
      let blob = new Blob([response], { type: 'text/xml' });
      return blob;
    } else {
      return null;
    }
  }, []);

  const uploadVideosToVendor = useCallback(
    async (videoIds: number[]) => {
      const response = await API.uploadVideosToVendor(videoIds);
      if (response.code === 2000) {
        messageStore.snackbar({
          message: t('Video(s) set to upload'),
          type: 'success',
        });
      } else {
        messageStore.snackbar({
          message: t('Videos failed to upload;&nbsp;') + response.message,
          type: 'error',
        });
      }
      videoStore.updateVideos([]);
    },
    [t]
  );

  const downloadVideos = useCallback(async (videoIds) => {
    settingsStore.updateGlobalLoading(true);
    const response = await API.getExportedVideos(videoIds);
    settingsStore.updateGlobalLoading(false);
    return response;
  }, []);

  const downloadVideo = useCallback(async (videoId) => {
    settingsStore.updateGlobalLoading(true);
    const response = await API.getExportedVideo(videoId);
    settingsStore.updateGlobalLoading(false);
    return response;
  }, []);

  /*   const downloadGPX = useCallback(async (videoUrl) => {
    const response = await API.getVideoFile(videoUrl);
    if (response) {
      let blob = new Blob([response.data], { type: "video/mp4" });
      saveAs(blob, `videoUrl`);
    }
  }, []); */

  const actionOnVideos = async () => {
    settingsStore.updateGlobalLoading(true);
    switch (selectedAction) {
      case 'Download':
        if (selectedVideos.length > 1) {
          const loadedUrls = await downloadVideos(selectedVideos);

          loadedUrls.data.URLs.map(async (video: any) => {
            const response = await API.getVideoFile(video.URL);
            saveAs(response, `Crowdcupa-Video-${moment().format('YYYY-MM-DD')}-${video.ID}`);
          });
        } else if (!!selectedVideos.length) {
          const loadedUrl = await downloadVideo(selectedVideos);
          const response = await API.getVideoFile(loadedUrl.data.URLs[0].URL);
          saveAs(response, `Crowdcupa-Video-${moment().format('YYYY-MM-DD')}-${loadedUrl.data.URLs[0].ID}`);
        }

        break;
      case 'Export GPX':
        if (selectedVideos.length > 1) {
          var zip = new JSZip();
          for (const videoId of selectedVideos) {
            const file = await getGPX(videoId);
            if (file) {
              zip.file(`GPX-${moment().format('YYYY-MM-DD')}-${videoId}.gpx`, file);
            }
          }
          zip.generateAsync({ type: 'blob' }).then(function (content: any) {
            saveAs(content, `GPX-${moment().format('YYYY-MM-DD')}.zip`);
          });
        } else if (!!selectedVideos.length) {
          showGPX(selectedVideos[0]);
        }
        break;

      case 'Export Geojson':
        getVideoGeojson(selectedVideos);
        break;

      case 'Upload to partner company':
        uploadVideosToVendor(selectedVideos);
        break;

      default:
        break;
    }
    settingsStore.updateGlobalLoading(false);
  };

  const handleChange = (event: { target: { value: any } }) => {
    setSelectedAction(event.target.value);
  };

  useEffect(() => {
    const actions = ['Download', 'Export GPX'];

    if (adminRole === 'superadmin') {
      actions.push('Export Geojson');
    }

    if (!!adminRole && ['superadmin', 'admin'].includes(adminRole)) {
      actions.push('Upload to partner company');
    }


    setRows(actions);
  }, [adminRole]);

  return (
    <div>
      <Grid container direction="row" justify="flex-end" alignItems="center" className={styles.gap}>
        <FormControl className={styles.formControl}>
          <InputLabel shrink id="project-select-label">
            Select video action
          </InputLabel>
          <Select
            labelId="project-select-label"
            id="project-select"
            value={!!selectedAction ? selectedAction : ''}
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {rows.map((item: any) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={actionOnVideos}>
          Apply
        </Button>
      </Grid>
    </div>
  );
};

export default inject('videoStore', 'settingsStore', 'messageStore', 'authStore')(observer(VideoActionSelect));
