import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Videos.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import { API } from '../../../api';
import { inject, observer } from 'mobx-react';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import ProjectSelect from '../../../components/UX/project-select/ProjectSelect';
import VideoTypeSelect from '../../../components/UX/video-type-select/VideoTypeSelect';
import DateRangeSelect from '../../../components/UX/date-range-select/DateRangeSelect';
import VideoList from '../../../components/UX/video-list/VideoList';
import { VideoData, VideoRequest } from '../../../models/video';
import UsersSelect from '../../../components/UX/users-select/UsersSelect';
import userStore from '../../../stores/userStore';
import videoStore from '../../../stores/videoStore';
import settingsStore from '../../../stores/settingsStore';
import moment from 'moment';
import authStore from '../../../stores/authStore';
import { getVideoStatus } from '../../../logic/get-video-status';

interface Props extends RouteComponentProps {}

const Videos = (props: Props) => {
  const { adminRole, globalProject } = authStore;
  const { selectedUsers } = userStore;
  const { selectedProject, selectedPeriod } = settingsStore;
  const { selectedVideoType } = videoStore;
  const [loading, setloading] = React.useState(true);

  const [videos, setVideos] = useState<VideoData[]>([]);
  const { t } = useTranslation();

  const useProject = useMemo(() => selectedProject ?? globalProject, [selectedProject, globalProject]);

  const loadVideoList = useCallback(async () => {
    // console.log("selectedUser", selectedUser);
    setloading(true);
    settingsStore.updateGlobalLoading(true);
    const videoRequest: VideoRequest = {
      projectID: !!useProject ? useProject : undefined,
      startDate: (function (startDate) {
        if (!!startDate) {
          const date = new Date(startDate);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          return moment(date).unix();
        } else {
          return undefined;
        }
      })(selectedPeriod?.startDate),
      endDate: (function (endDate) {
        if (!!endDate) {
          const date = new Date(endDate);
          date.setHours(23);
          date.setMinutes(59);
          date.setSeconds(59);
          return moment(date).unix();
        } else {
          return undefined;
        }
      })(selectedPeriod?.endDate),
      userIDs: !!selectedUsers.length ? selectedUsers.map((user) => user.ID) : [],
      limit: 1000,
      offset: 0,
    };
    const response = await API.getVideos(videoRequest);
    // console.log("response", response);
    if (response) {
      setloading(false);
      settingsStore.updateGlobalLoading(false);
      setVideos(response.data.videos);
      messageStore.snackbar({
        message: t('Videos loaded'),
        type: 'success',
      });
      // sessionsStore.updateSessions(response.data.sessions);
      //setSessionsVideos(response.data.sessions);
    } else {
      setloading(false);
      settingsStore.updateGlobalLoading(false);
      messageStore.snackbar({
        message: t('Failed fetching user sessions'),
        type: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, selectedProject, selectedPeriod, selectedUsers.length]);

  const filteredVideos = useMemo((): VideoData[] => {
    if (!!videos && videos.length) {
      // console.log(selectedVideoType);
      if (!!selectedVideoType) {
        switch (selectedVideoType) {
          case 'isApproved':
            return videos.filter((video) => getVideoStatus(video) === 'approved');
          case 'isRejected':
            return videos.filter((video) => getVideoStatus(video) === 'rejected');
          case 'isFinished':
            return videos.filter((video) => getVideoStatus(video) === 'finished');
          case 'isUploaded':
            return videos.filter((video) => getVideoStatus(video) === 'pending');
          case 'isVaisalaStart':
            return videos.filter((video) => getVideoStatus(video) === 'uploading');
          case 'isVaisalaEnd':
            return videos.filter((video) => getVideoStatus(video) === 'inVaisala');
          case 'isVaisalaError':
            return videos.filter((video) => getVideoStatus(video) === 'errored');
          default:
            return videos;
        }
      } else {
        return videos;
      }
    } else {
      return [];
    }
  }, [selectedVideoType, videos]);

  useEffect(() => {
    loadVideoList();
  }, [loadVideoList]);

  return (
    <SimpleLayout passedStyles={styles.root}>
      <CarchupaPaper type="row" parentStyle={styles.paper}>
        {console.log(videos, 'videossss')}
        {adminRole === 'onProject' ? null : <ProjectSelect />}
        <DateRangeSelect />
        <UsersSelect usersDropdownTitle={'None selected'} />
        <VideoTypeSelect />
      </CarchupaPaper>
      {loading ? null : !!filteredVideos.length ? (
        <VideoList videoList={filteredVideos} pageSizeOptions={[5, 10, 15]} {...props} />
      ) : (
        <CarchupaPaper type="row" parentStyle={styles.paper}>
          <div>No videos</div>
        </CarchupaPaper>
      )}
    </SimpleLayout>
  );
};

export default inject('sessionsStore', 'userStore', 'messageStore')(observer(Videos));
