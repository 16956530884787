import React, { useState, useMemo, useCallback, FormEvent } from 'react';
import { Grid, TextField, Button, Card, Checkbox } from '@material-ui/core';
import { API } from '../../../api';
import { ROUTES } from '../../../parameters/routes';
import { useHistory } from 'react-router-dom';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import styles from '../UserManagement.module.scss';
import authStore from '../../../stores/authStore';

const Login = () => {
  const history = useHistory();
  const [data, setData] = useState({ email: '', password: '' });
  const [login, loginPending] = useCallbackWithLoading(API.login);
  const canSubmit = useMemo(() => !!data.email && !!data.password, [data]);
  const [error, setError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { firstRoute } = authStore;

  const toggleRememberMe = useCallback(() => setRememberMe((v) => !v), []);

  const handleDataChange = useCallback(({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setData((d) => ({ ...d, [currentTarget.id]: currentTarget.value }));
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!canSubmit) {
        return;
      }
      setError(false);

      try {
        await login(data.email, data.password, { rememberMe });
        history.push(firstRoute);
        authStore.setfirstRoute(ROUTES.home);
      } catch (error) {
        setError(true);
        setData((d) => ({ ...d, password: '' }));
      }
    },
    [canSubmit, login, data.email, data.password, rememberMe, history, firstRoute]
  );

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={styles.root}>
      <form onSubmit={handleSubmit}>
        <Card elevation={0}>
          <Grid container direction="column" justify="center" alignItems="center">
            <TextField className={styles.inputs} required id="email" label="Email" onChange={handleDataChange} />
            <TextField
              className={styles.inputs}
              required
              id="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={handleDataChange}
            />
            <br />
            <Grid>
              <Checkbox id="check" name="checkbox" checked={rememberMe} onChange={toggleRememberMe} />
              <span className={styles.remember_me_text}>Remember me</span>
            </Grid>
            <Button className={styles.inputs} type="submit" variant="contained" disabled={!canSubmit || loginPending}>
              Login
            </Button>
            <br />
            <Button className={styles.inputs} variant="outlined" disabled={loginPending} onClick={() => history.push(ROUTES.passwordRequest)}>
              Forgot password
            </Button>
          </Grid>
        </Card>
        {error ? <div className={styles.error}>The provided email and password do not match.</div> : null}
      </form>
    </Grid>
  );
};

export default Login;
