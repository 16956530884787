import React, { useMemo } from 'react';
import { Route, RouteComponentProps, Redirect, Switch } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ROUTES } from '../../../parameters/routes';
import Videos from '../videos/Videos';
import Users from '../users/Users';
import User from '../user/UserCarchupa';
import Projects from '../projects/Projects';
import Project from '../project/Project';
import AddProject from '../addProject/AddProject';
import Sessions from '../sessions/Sessions';
import authStore from '../../../stores/authStore';
import sessionsStore from '../../../stores/sessionsStore';
import { inject, observer } from 'mobx-react';
import styles from './Home.module.scss';
import PeopleIcon from '@material-ui/icons/People';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import userStore from '../../../stores/userStore';
import Leaderboard from '../leaderboard/Leaderboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import FlagIcon from '@material-ui/icons/Flag';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Payments from '../finance/Payments';
import UserPayments from '../userPayments/UserPayments';
import Coins from '../coins/Coins';
import BackupIcon from '@material-ui/icons/Backup';
import MessageIcon from '@material-ui/icons/Message';
import MapIcon from '@material-ui/icons/Map';
import logo from '../../../assets/0c037b25.png';
import { Grid, Button, Menu, MenuItem } from '@material-ui/core';
import classnames from 'classnames';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MessageService from '../../../services/messages/MessageService';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import Overview from '../overview/Overview';
import Video from '../video/Video';
import Notifications from '../notifications/Notifications';
import Superadmin from '../superadmin/Superadmin';
import SuperAdminRoute from '../../../components/UX/system/SuperAdminRoute';
import AdminRoute from '../../../components/UX/system/AdminRoute';
import GlobalMap from '../globalMap/GlobalMap';

const Home = (props: RouteComponentProps) => {
  const { history } = props;
  const { t, i18n } = useTranslation();
  const [openSideNav, setOpenSideNav] = React.useState(false);
  const { adminRole } = authStore;
  const drawerWidth = openSideNav ? 240 : 55;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#2e1a55',
      },
      drawer: {
        width: openSideNav ? '240px' : '55px',
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
        transition: 'all 150ms ease-in-out',
      },
      drawerContainer: {
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      content: {
        flexGrow: 1,
        transition: 'all 150ms ease-in-out',
        height: 'calc(100vh - 64px)',
      },
    })
  );
  const [languageAnchorEl, setLanguageAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const classes = useStyles();
  const customclass = useMemo(
    () =>
      classnames({
        [styles.smallSideNav]: !openSideNav,
      }),
    [openSideNav]
  );

  const setPage = (route: string) => {
    history.push(`${route}`);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    handleLanguageClose();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MessageService />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <img src={logo} className={styles.logo} alt="Logo" />
                <Typography variant="h6" noWrap>
                  Carchupa {userStore.selectedUser ? `/ ${t('User')} ${userStore.selectedUser.ID}` : null}{' '}
                  {sessionsStore.selectedSession ? `/ ${t('Session')} ${sessionsStore.selectedSession.ID}` : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Button aria-controls={'language-menu'} className={styles.languageMenu} aria-haspopup="true" onClick={handleLanguageClick}>
                <LanguageIcon />
              </Button>
              <Menu id={'language-menu'} anchorEl={languageAnchorEl} keepMounted open={Boolean(languageAnchorEl)} onClose={handleLanguageClose}>
                <MenuItem
                  onClick={() => changeLanguage('fi')}
                  className={classnames({
                    [styles.hightlight]: i18n.language === 'fi',
                  })}
                >
                  {t('fi')}
                </MenuItem>
                <MenuItem
                  onClick={() => changeLanguage('en')}
                  className={classnames({
                    [styles.hightlight]: i18n.language === 'en',
                  })}
                >
                  {t('en')}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={`${classes.drawer} ${customclass}`}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={`${classes.drawerContainer} ${styles.drawerContainer}`}>
          <div>
            <List>
              {adminRole === 'superadmin' && (
                <>
                  <ListItem
                    button
                    selected={history.location.pathname.includes(ROUTES.overview)}
                    classes={{
                      selected: styles.highlight,
                    }}
                    onClick={() => setPage(ROUTES.overview)}
                  >
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Overview')} />
                  </ListItem>

                  <ListItem
                    button
                    selected={history.location.pathname.includes(ROUTES.users)}
                    classes={{
                      selected: styles.highlight,
                    }}
                    onClick={() => setPage(ROUTES.users)}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Users')} />
                  </ListItem>
                  <ListItem
                    button
                    selected={history.location.pathname.includes(ROUTES.projects)}
                    classes={{
                      selected: styles.highlight,
                    }}
                    onClick={() => setPage(ROUTES.projects)}
                  >
                    <ListItemIcon>
                      <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Projects')} />
                  </ListItem>
                </>
              )}

              {/* <ListItem
                button
                selected={history.location.pathname.includes("sessions")}
                classes={{
                  selected: styles.highlight,
                }}
                onClick={() => {
                  sessionsStore.updateSession(undefined);
                  userStore.selectedUser
                    ? setPage(
                        ROUTES.createUserSessionsUrl(userStore.selectedUser.ID)
                      )
                    : setPage(ROUTES.sessions);
                }}
              >
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary={t("Sessions")} />
              </ListItem> */}
              <ListItem
                button
                selected={history.location.pathname.includes('videos')}
                classes={{
                  selected: styles.highlight,
                }}
                onClick={() => {
                  sessionsStore.selectedSession ? setPage(ROUTES.createVideosUrl(sessionsStore.selectedSession.ID)) : setPage(ROUTES.videos);
                }}
              >
                <ListItemIcon>
                  <ArtTrackIcon />
                </ListItemIcon>
                <ListItemText primary={t('Videos')} />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem
                button
                selected={history.location.pathname.includes('global-map')}
                classes={{
                  selected: styles.highlight,
                }}
                onClick={() => setPage(ROUTES.globalmap)}
              >
                <ListItemIcon>
                  <MapIcon />
                </ListItemIcon>
                <ListItemText primary={t('Map')} />
              </ListItem>
            </List>
            <Divider />
            {adminRole === 'superadmin' && (
              <>
                <List>
                  <ListItem
                    button
                    selected={history.location.pathname.includes('payments')}
                    classes={{
                      selected: styles.highlight,
                    }}
                    onClick={() => setPage(ROUTES.payments)}
                  >
                    <ListItemIcon>
                      <LocalAtmIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Payments')} />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem
                    button
                    selected={history.location.pathname.includes('leaderboard')}
                    classes={{
                      selected: styles.highlight,
                    }}
                    onClick={() => setPage(ROUTES.leaderboard)}
                  >
                    <ListItemIcon>
                      <FlagIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Leaderboard')} />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem
                    button
                    selected={history.location.pathname.includes('coin-upload')}
                    classes={{
                      selected: styles.highlight,
                    }}
                    onClick={() => setPage(ROUTES.coinUpload)}
                  >
                    <ListItemIcon>
                      <BackupIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Coins')} />
                  </ListItem>
                </List>
                <Divider />
              </>
            )}
            {adminRole === 'superadmin' || adminRole === 'admin' ? (
              <>
                <List>
                  <ListItem
                    button
                    selected={history.location.pathname.includes('notifications')}
                    classes={{
                      selected: styles.highlight,
                    }}
                    onClick={() => setPage(ROUTES.notifications)}
                  >
                    <ListItemIcon>
                      <MessageIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Notifications')} />
                  </ListItem>
                </List>
              </>
            ) : null}
            {adminRole === 'superadmin' && (
              <>
                <Divider />
                <List>
                  <ListItem
                    button
                    selected={history.location.pathname.includes(ROUTES.admins)}
                    classes={{
                      selected: styles.highlight,
                    }}
                    onClick={() => setPage(ROUTES.admins)}
                  >
                    <ListItemIcon>
                      <AssignmentIndIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Admins')} />
                  </ListItem>
                </List>
                <Divider />
              </>
            )}
            <List>
              <ListItem button onClick={() => authStore.logout()}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={t('Logout')} />
              </ListItem>
            </List>
          </div>
          <div>
            <List>
              <ListItem button onClick={() => setOpenSideNav(!openSideNav)}>
                <ListItemIcon>{openSideNav ? <NavigateBeforeIcon /> : <NavigateNextIcon />}</ListItemIcon>
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Switch>
          <SuperAdminRoute path={ROUTES.admins} exact={true} component={Superadmin} />
          <SuperAdminRoute path={ROUTES.users} exact={true} component={Users} />
          <SuperAdminRoute path={ROUTES.sessions} exact={true} component={Sessions} />
          <SuperAdminRoute path={ROUTES.userSessions} component={Sessions} />
          <Route path={ROUTES.videos} component={Videos} />
          <SuperAdminRoute path={ROUTES.sessionVideos} component={Videos} />
          <Route path={ROUTES.video} component={Video} />
          <Route path={ROUTES.globalmap} component={GlobalMap} />
          <SuperAdminRoute path={ROUTES.user} component={User} />
          <SuperAdminRoute path={ROUTES.projects} component={Projects} />
          <SuperAdminRoute path={ROUTES.project} component={Project} />
          <SuperAdminRoute path={ROUTES.addProject} component={AddProject} />
          <SuperAdminRoute path={ROUTES.leaderboard} exact={true} component={Leaderboard} />
          <SuperAdminRoute path={ROUTES.payments} exact={true} component={Payments} />
          <SuperAdminRoute path={ROUTES.userPayments} exact={true} component={UserPayments} />
          <SuperAdminRoute path={ROUTES.coinUpload} exact={true} component={Coins} />
          <SuperAdminRoute path={ROUTES.overview} exact={true} component={Overview} />
          <AdminRoute path={ROUTES.notifications} exact={true} component={Notifications} />
          <Route path={ROUTES.home} render={() => <Redirect to={adminRole === 'superadmin' ? ROUTES.overview : ROUTES.videos} />} />
        </Switch>
      </main>
    </div>
  );
};

export default inject('authStore', 'userStore', 'sessionsStore')(observer(Home));
