import React, { useCallback, useEffect } from 'react';
import styles from './AddProject.module.scss';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { Formik, Form, Field, useField } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';
import StateSelect from '../../../components/UX/state-select/StateSelect';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import { API } from '../../../api';
import { CarchupaProjectAdd } from '../../../models/general';
import { inject, observer } from 'mobx-react';
import settingsStore from '../../../stores/settingsStore';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';

interface Props {}

const CustomTextArea = ({ ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      <textarea className={styles.textArea} {...field} {...props} />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};

const AddProject = (props: Props) => {
  const { t } = useTranslation();
  const { selectedState } = settingsStore;

  const [send, sendPending] = useCallbackWithLoading(API.createProject);
  const load = useCallback(async () => {}, []);

  const NotificationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  const sendNotification = useCallback(
    async (values: { name: any; description: any; selectedState: any }, setSubmitting: (isSubmitting: boolean) => void, resetForm) => {
      const { name, description } = values;
      const data: CarchupaProjectAdd = {
        countryID: selectedState,
        name: name,
        description: description,
      };

      await send(data)
        .then((res) => {
          messageStore.snackbar({
            message: `${t('Project created')}`,
            type: 'info',
          });
          resetForm({});
        })
        .catch((err) =>
          messageStore.snackbar({
            message: `${t('Failed to create project')}`,
            type: 'error',
          })
        );
      setSubmitting(false);
    },
    [selectedState, send, t]
  );

  useEffect(() => {
    load();
  }, [load]);

  return (
    <SimpleLayout passedStyles={styles.root}>
      <CarchupaPaper type="column" parentStyle={`${styles.paper} ${styles.messagebox}`} childStyle={styles.generalInfo}>
        <div>
          <Formik
            initialValues={{
              name: '',
              description: '',
            }}
            onSubmit={(values: any, { setSubmitting, resetForm }: any) => {
              setSubmitting(true);
              sendNotification(values, setSubmitting, resetForm);
            }}
            validationSchema={NotificationSchema}
          >
            {({ errors, touched, isSubmitting, setFieldValue }: any) => (
              <Form className={styles.notificationForm}>
                <StateSelect allStatePlaceholder={'Select state'} />
                <Field type="textarea" name="name" placeholder="Name" disabled={isSubmitting} />
                {errors.name && touched.name ? <div>{errors.name}</div> : null}

                <CustomTextArea name="description" rows="6" placeholder="Description" disabled={isSubmitting} />

                <div className={styles.sendButton}>
                  <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || sendPending} className={styles.submitButton}>
                    Create project
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CarchupaPaper>
    </SimpleLayout>
  );
};

export default inject('settingsStore', 'messageStore')(observer(AddProject));
