import { API } from '../api';
import { saveAs } from 'file-saver';
import settingsStore from '../stores/settingsStore';

export async function getVideoGeojson(videoIDs: number[]) {
  settingsStore.updateGlobalLoading(true);
  const response = await API.getGeojson(videoIDs);
  console.log(response, 'sdsds');
  if (response) {
    let blob = new Blob([JSON.stringify(response)], { type: 'application/geo+json' });
    saveAs(blob, `GEOJSON-${videoIDs.length === 1 ? videoIDs[0] : videoIDs.join('-')}.geojson`);
    settingsStore.updateGlobalLoading(false);
  } else {
    settingsStore.updateGlobalLoading(false);
  }
}
