import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Map, GoogleApiWrapper, Marker, Polyline, InfoWindow } from 'google-maps-react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { API } from '../../../api';
import messageStore from '../../../stores/messageStore';
import styles from './VideoMap.module.scss';
import playerStateStore from '../../../stores/playerStateStore';

const VideoMapContainer = (props) => {
  const { playerProgressState, userPosition } = playerStateStore;
  const { t } = useTranslation();
  const { google } = props;
  const match = useRouteMatch();
  const videoId = Number(match.params.videoId);
  const [waypoints, setWaypoints] = useState([]);
  const [coords, setCoords] = useState([]);
  const [infoVisible, setInfoVisible] = useState(false);
  const [bearingLocation, setBearingLocation] = useState({});

  const fetchWaypoints = useCallback(async () => {
    const response = await API.getWaypoints({
      videoIDs: [videoId],
    });
    if (response) {
      if (!!response.data) {
        setWaypoints(response.data.waypoints);
      }
    } else {
      messageStore.snackbar({
        message: t('Failed fetching waypoints'),
        type: 'error',
      });
    }
  }, [videoId, t]);

  const onPolylineClick = (props, polyLine, e) => {
    const latlng = e.latLng;
    let needle = {
      minDistance: 999,
      index: -1,
      latlng: null,
    };
    polyLine.getPath().forEach(function (routePoint, index) {
      const dist = google.maps.geometry.spherical.computeDistanceBetween(latlng, routePoint);
      if (dist < needle.minDistance) {
        needle.minDistance = dist;
        needle.index = index;
        needle.latlng = routePoint;
      }
    });

    playerStateStore.updateUserPosition({
      lat: waypoints[needle.index]?.latitude,
      lng: waypoints[needle.index]?.longitude,
      road: waypoints[needle.index]?.roadname_roadnumber,
      roadid: waypoints[needle.index]?.roadid,
      chainage: waypoints[needle.index]?.chainage,
    });

    setBearingLocation({
      lat: waypoints[needle.index + 1]?.latitude,
      lng: waypoints[needle.index + 1]?.longitude,
    });
    playerStateStore.updatePlayerSeekState(waypoints[needle.index]?.extensions.videoTimestampSeconds);
  };

  useEffect(() => {
    fetchWaypoints();
  }, [fetchWaypoints]);

  useEffect(() => {
    const coords = [];
    waypoints.length > 1 &&
      waypoints.map((point) =>
        coords.push({
          lat: point.latitude,
          lng: point.longitude,
        })
      );
    setCoords(coords);
    waypoints.length > 1 && setBearingLocation({ lat: waypoints[1].latitude, lng: waypoints[1].longitude });
  }, [waypoints]);

  useEffect(() => {
    if (waypoints.length > 1) {
      const point = waypoints[waypoints.findIndex((point) => point.extensions.videoTimestampSeconds === Math.round(playerProgressState + 1))];
      const point2 = waypoints[waypoints.findIndex((point) => point.extensions.videoTimestampSeconds === Math.round(playerProgressState + 2))];

      if (Math.round(playerProgressState) > 0) {
        playerStateStore.updateUserPosition({
          lat: point?.latitude,
          lng: point?.longitude,
          road: point?.roadname_roadnumber,
          roadid: point?.roadid,
          chainage: point?.chainage,
        });
      }
      setBearingLocation({
        lat: point2?.latitude,
        lng: point2?.longitude,
      });
    }
  }, [waypoints, playerProgressState]);

  const bearing = useMemo(() => {
    const y = Math.sin(bearingLocation.lng - userPosition.lng) * Math.cos(bearingLocation.lat);
    const x =
      Math.cos(userPosition.lat) * Math.sin(bearingLocation.lat) -
      Math.sin(userPosition.lat) * Math.cos(bearingLocation.lat) * Math.cos(bearingLocation.lng - userPosition.lng);
    const z = Math.atan2(y, x);
    return ((z * 180) / Math.PI + 360) % 360;
  }, [userPosition, bearingLocation]);

  return (
    <div className={styles.container}>
      {coords.length > 1 && google ? (
        <div>
          <Map google={google} zoom={15} initialCenter={coords[0]} center={userPosition}>
            <Marker
              onClick={() => setInfoVisible(true)}
              position={Object.entries(userPosition).length === 0 ? coords[0] : userPosition}
              icon={{
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 4,
                fillColor: '#C51162',
                fillOpacity: 0.8,
                strokeWeight: 1,
                anchor: new google.maps.Point(0, 2),
                rotation: bearing,
              }}
            />
            <Polyline path={coords} strokeColor="#0000FF" strokeOpacity={0.8} strokeWeight={4} onClick={onPolylineClick} />
            {Object.entries(userPosition).length > 0 && (
              <InfoWindow visible={infoVisible} position={{ lng: userPosition.lng, lat: userPosition.lat }} onClose={() => setInfoVisible(false)}>
                <div>
                  <h1>{userPosition.road ? userPosition.road : 'No information'}</h1>
                </div>
              </InfoWindow>
            )}
          </Map>
        </div>
      ) : null}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(inject('playerStateStore', 'messageStore')(observer(VideoMapContainer)));
