import axios, { AxiosRequestConfig } from 'axios';
import { AuthStore } from '../stores/authStore';
import {
  LoginOptions,
  Admin,
  LoginResponse,
  UsersResponse,
  LeaderboardResponse,
  LeaderboardWithFilteringResponse,
  LeaderboardRequest,
  PaymentsResponse,
  PutResponse,
  ResetResponse,
  ProjectsResponse,
  ProjectResponse,
  ProjectRequest,
  UserResponse,
  StateResponse,
  NotificationBroadcast,
  CarchupaProjectAdd,
  CSVFilesResponse,
  ProjectCollectablesResponse,
  AdminsResponse,
  PaymentsSingleUserResponse,
  WaypointsResponse,
} from '../models/general';
import { SessionResponse } from '../models/sessions';
import { SingleVideoResponse, VideoRequest, VideoResponse } from '../models/video';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseParams = (params: { [x: string]: any[] }) => {
  const keys = Object.keys(params);
  let options = '';

  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === 'object';
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element: any) => {
        options += `${key}=${element}&`;
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};

export interface API {
  login(email: string, password: string, options: LoginOptions): Promise<void>;
  resetPasswordRequest(email: string): Promise<ResetResponse>;
  resetPassword(token: string, password: string): Promise<ResetResponse>;
  getAdminInfo(): Promise<Admin>;
  getAdminsList(): Promise<AdminsResponse>;
  deleteAdmins(adminIds: number[]): Promise<any>;
  addAdmin(firstName: string, lastName: string, username: string, email: string): Promise<any>;
  getUser(userId: number): Promise<UserResponse>;
  getUsers(selectedProject?: number | null): Promise<UsersResponse>;
  deleteUsers(userIds: number[]): Promise<any>;
  deleteCollectables(csvFileIDs: number[]): Promise<any>;
  deleteAllCollectables(): Promise<any>;
  getAllSessions(): Promise<any>;
  getUserSessions(userId?: number): Promise<SessionResponse>;
  getWaypoints({
    projectID,
    videoIDs,
    offset,
    limit,
    startDate,
    endDate,
  }: {
    videoIDs: number[];
    projectID?: string;
    offset?: number;
    limit?: number;
    startDate?: number;
    endDate?: number;
  }): Promise<WaypointsResponse>;
  getVideos(videoRequest: VideoRequest): Promise<VideoResponse>;
  getVideo(videoId?: number | string): Promise<SingleVideoResponse>;
  getUserVideos(userId?: number): Promise<any>;
  validateVideo(validated: boolean, videoID: number, message: string): Promise<PutResponse>;
  getLeaderboard(): Promise<LeaderboardResponse>;
  getLeaderboardPerProjectAndCountry(selectedProjectAndCountry?: LeaderboardRequest): Promise<LeaderboardWithFilteringResponse>;
  getUserPayments(startMonth: number, endMonth: number, projectId: number | null): Promise<PaymentsResponse>;
  getSingleUserPayments(ID: number, startDate: number, endDate: number, projectId: number | null): Promise<PaymentsSingleUserResponse>;
  setUserPayments(userIds: number[], startDate: number, endDate: number): Promise<PutResponse>;
  getProjectCollectables(projectId: number): Promise<ProjectCollectablesResponse>;
  uploadCollectables(file: any, projectID: number): Promise<PutResponse>;
  getGPX(videoID: number): Promise<any>;
  getExportedVideos(videoID: number[]): Promise<any>;
  getExportedVideo(videoID: number): Promise<any>;
  getProject(userId: number): Promise<ProjectResponse>;
  getProjectList(projectRequest?: ProjectRequest): Promise<ProjectsResponse>;
  createProject(data: CarchupaProjectAdd): Promise<any>;
  deleteProject(projectId: number): Promise<any>;
  getVideoFile(videoURL: string): Promise<Blob>;
  getStateList(): Promise<StateResponse>;
  sendNotifications(data: NotificationBroadcast): Promise<any>;
  uploadVideosToVendor(videoIDs: number[]): Promise<any>;

  getCollectablesList(projectId: number | null): Promise<CSVFilesResponse>;
  getGeojson(videoIDs: number[]): Promise<any>;
  uploadGeojson(file: any): Promise<any>;
}

export function createAPI(authStore: AuthStore): API {
  const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  function tokenInterceptor(config: AxiosRequestConfig) {
    const token = authStore.token;
    if (token) {
      config.headers['jwt'] = `${token}`;
    }
    return config;
  }

  instance.interceptors.request.use(tokenInterceptor);

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response.status === 400) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.response);
    }
  );

  function handleLoginResponse(response: LoginResponse, options: LoginOptions = { rememberMe: false }) {
    const { token, user } = response.data;
    authStore.setToken(token, options);
    authStore.setAdminRole(user.role, options);
    authStore.setGlobalProject(user.projectID || undefined, options);
  }

  return {
    async login(email, password, options) {
      const res = await instance.post('/auth/signin', { email, password }, { params: {} });
      handleLoginResponse(res.data, options);
    },

    async getAdminInfo() {
      const res = await instance.get('/auth/me', {
        params: {},
      });
      return res.data;
    },

    async getAdminsList() {
      const res = await instance.get('users/admins');
      return res.data;
    },

    async deleteAdmins(adminIds: number[]) {
      const res = await instance.delete('/users/admins', {
        data: {
          IDs: adminIds,
        },
      });
      return res.data;
    },

    async addAdmin(firstName, lastName, username, email) {
      const res = await instance.post('users/admins', { firstName, lastName, username, email });
      return res.data;
    },

    async resetPassword(token, password) {
      const res = await instance.put(`/users/password/${token}`, { password }, { params: {} });
      return res.data;
    },

    async resetPasswordRequest(email) {
      const res = await instance.post('/users/forgot-password', { email }, { params: {} });
      return res.data;
    },

    async getUser(userId: number) {
      const res = await instance.get(`/users/${userId}/`, {
        params: {},
      });
      return res.data;
    },

    async getUsers(selectedProject?: number | null) {
      const res = await instance.get('/users', {
        params: !!selectedProject
          ? {
              projectID: selectedProject,
            }
          : {},
      });
      return res.data;
    },

    async deleteUsers(userIds: number[]) {
      console.log('userIds', userIds);
      const res = await instance.delete('/users', {
        data: {
          IDs: userIds,
        },
      });
      return res.data;
    },

    async getAllSessions() {
      const res = await instance.get('/sessions', {
        params: {},
      });
      return res.data;
    },

    async getUserSessions(userId: number) {
      const res = await instance.get(`/users/${userId}/sessions`, {
        params: {},
      });
      return res.data;
    },

    async getVideos(videoRequest: VideoRequest) {
      const res = await instance.get('/sessions/videos', {
        params: videoRequest,
      });
      return res.data;
    },

    async getVideo(videoId: number | string) {
      const res = await instance.get(`/sessions/videos/${videoId}`, {
        params: {},
      });
      return res.data;
    },

    async getUserVideos(userId: number) {
      const res = await instance.get(`/users/${userId}/sessions`, {
        params: {},
      });
      return res.data;
    },

    async uploadVideosToVendor(videoIDs: number[]) {
      // TODO BE chnage
      const res = await instance.post(`/sessions/videos/uploadZipVideoGPX`, { videoIDs });
      return res.data;
    },

    async validateVideo(validated: boolean, videoID: number, message: string) {
      const res = await instance.put(`/sessions/videos/${videoID}/validate/?action=${validated ? 'accept' : 'reject'}`, {
        note: message,
      });
      return res.data;
    },

    async getLeaderboard() {
      const res = await instance.get('/users/leaderboard', {
        params: {},
      });
      return res.data;
    },

    async getLeaderboardPerProjectAndCountry(selectedProjectAndCountry?: LeaderboardRequest | undefined) {
      const res = await instance.get('/projects/leaderboard', {
        params: selectedProjectAndCountry,
      });
      return res.data;
    },

    async getUserPayments(startMonth: number, endMonth: number, projectId: number | null) {
      const res = await instance.get(`/users/payments?${projectId ? 'projectID=' + projectId : ''}`, {
        params: {
          startMonth,
          endMonth,
        },
      });
      return res.data;
    },

    async getSingleUserPayments(ID: number, startMonth: number, endMonth: number, projectId: number | null) {
      const res = await instance.get(`/users/payments/${ID}?${projectId ? 'projectID' + projectId : ''}`, {
        params: {
          startMonth,
          endMonth,
        },
      });
      return res.data;
    },

    async setUserPayments(IDs: number[], startMonth: number, endMonth: number) {
      const res = await instance.put('/users/payments', {
        IDs,
        startMonth,
        endMonth,
      });
      return res.data;
    },

    async getProjectCollectables(projectId: number) {
      const res = await instance.get(`/projects/${projectId}/collectables`, {
        params: {},
      });
      return res.data;
    },

    async uploadCollectables(file: any, projectID: number) {
      const res = await instance.post(`/upload/collectables/csv?projectID=${projectID}`, file, {
        params: {},
      });
      return res.data;
    },

    async getGPX(videoID: number) {
      const res = await instance.get(`sessions/videos/${videoID}/gpx`, { params: {} });
      return res.data;
    },

    async getWaypoints({ projectID, videoIDs, offset, limit, startDate, endDate }) {
      const params: any = {};

      params.offset = offset ? offset : 0;
      params.limit = limit ? limit : 10;
      params.videoIDs = videoIDs

      if (projectID) {
        params.projectID = projectID;
      }

      
      if (projectID) {
        params.projectID = projectID;
      }

      if (startDate) {
        params.startDate = startDate;
      }

      if (endDate) {
        params.endDate = endDate;
      }
      if (endDate) {
        params.endDate = endDate;
      }

      console.log('params',params)

      try {
        const res = await instance.get(`sessions/videos/export/waypoints`, {
          params,
        });
        return res.data;
      } catch (error) {
        return false;
      }
    },

    async getExportedVideos(videoIDs: number[]) {
      const res = await instance.get(`sessions/videos/export/videos`, {
        params: {
          videoIDs: videoIDs,
        },
      });
      return res.data;
    },

    async getExportedVideo(videoID: number) {
      const res = await instance.get(`sessions/videos/export/videos?videoIDs=${videoID}`, {
        params: {},
      });
      return res.data;
    },

    async getVideoFile(videoURL: string) {
      const res = await instance.get(videoURL, { params: {}, responseType: 'blob' });
      return res.data;
    },

    async getProjectList(projectRequest?: ProjectRequest | null) {
      const res = await instance.get('/projects', {
        params: projectRequest,
      });
      return res.data;
    },

    async getProject(projectId: number) {
      const res = await instance.get(`/projects/${projectId}/`, {
        params: {},
      });
      return res.data;
    },

    async getStateList() {
      const res = await instance.get('/projects/countries', {
        params: {},
      });
      return res.data;
    },

    async sendNotifications(data: NotificationBroadcast) {
      const res = await instance.post('/users/notifications/broadcast', data);
      return res.data;
    },

    async createProject(data: CarchupaProjectAdd) {
      const res = await instance.post('/projects', data);
      return res.data;
    },

    async deleteCollectables(csvFileIDs: number[]) {
      const res = await instance.delete('/upload/csv/delete', {
        data: {
          csvFileIDs,
        },
      });
      return res.data;
    },

    async deleteAllCollectables() {
      const res = await instance.delete(`/collectables`, {
        params: {},
      });
      return res.data;
    },

    async deleteProject(projectId: number) {
      const res = await instance.delete(`/projects/${projectId}/`, {
        params: {},
      });
      return res.data;
    },

    async getCollectablesList(projectId: number) {
      const res = await instance.get(`/upload/csv/getByProjectID/${!!projectId || projectId === 0 ? projectId : ''}`, {
        params: {},
      });
      return res.data;
    },

    async getGeojson(videoIDs: number[]) {
      console.log('videoIDs', videoIDs);

      const res = await instance.get(`/sessions/videos/export/getGeoJSON`, {
        params: {
          videoIDs: videoIDs,
        },
      });
      return res.data;
    },

    async uploadGeojson(file: any) {
      const res = await instance.post(`/sessions/uploadGeoJSONFile`, file, {
        params: {},
      });
      return res.data;
    },
  };
}
