import { observable, action, decorate, computed } from 'mobx';
import { AuthToken, LoginOptions, AdminRole } from '../models/general';
import { ROUTES } from '../parameters/routes';

const TOKEN_STORAGE_KEY = 'authorization/token';
const ADMIN_ROLE_STORAGE_KEY = 'authorization/adminRole';
const PROJECTID_STORAGE_KEY = 'authorization/project';

export class AuthStore {
  token?: AuthToken = window.localStorage.getItem(TOKEN_STORAGE_KEY) ?? window.sessionStorage.getItem(TOKEN_STORAGE_KEY) ?? undefined;
  adminRole?: AdminRole = window.localStorage.getItem(ADMIN_ROLE_STORAGE_KEY) ?? window.sessionStorage.getItem(ADMIN_ROLE_STORAGE_KEY) ?? undefined;
  globalProject?: number =
    Number(window.localStorage.getItem(PROJECTID_STORAGE_KEY)) ?? Number(window.sessionStorage.getItem(PROJECTID_STORAGE_KEY)) ?? undefined;

  firstRoute: string = ROUTES.home;

  setToken(token: AuthToken, options: LoginOptions) {
    this.clearToken();
    if (options.rememberMe) {
      window.localStorage.setItem(TOKEN_STORAGE_KEY, token);
    } else {
      window.sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
    }
    this.token = token;
  }
  clearToken() {
    window.localStorage.removeItem(TOKEN_STORAGE_KEY);
    window.sessionStorage.removeItem(TOKEN_STORAGE_KEY);
    this.token = undefined;
  }
  setAdminRole(adminRole: AdminRole, options: LoginOptions) {
    this.clearAdminRole();
    if (options.rememberMe) {
      window.localStorage.setItem(ADMIN_ROLE_STORAGE_KEY, adminRole);
    } else {
      window.sessionStorage.setItem(ADMIN_ROLE_STORAGE_KEY, adminRole);
    }
    this.adminRole = adminRole;
  }
  clearAdminRole() {
    window.localStorage.removeItem(ADMIN_ROLE_STORAGE_KEY);
    window.sessionStorage.removeItem(ADMIN_ROLE_STORAGE_KEY);
    this.adminRole = undefined;
  }

  setGlobalProject(projectID: any, options: LoginOptions) {
    this.clearGlobalProject();
    if (projectID) {
      if (options.rememberMe) {
        window.localStorage.setItem(PROJECTID_STORAGE_KEY, projectID.toString());
      } else {
        window.sessionStorage.setItem(PROJECTID_STORAGE_KEY, projectID.toString());
      }
    }
    this.globalProject = projectID;
  }

  clearGlobalProject() {
    window.localStorage.removeItem(PROJECTID_STORAGE_KEY);
    window.sessionStorage.removeItem(PROJECTID_STORAGE_KEY);
    this.globalProject = undefined;
  }

  setfirstRoute(route: string) {
    this.firstRoute = route;
  }

  clearfirstRoute() {
    this.firstRoute = ROUTES.home;
  }

  logout() {
    this.clearToken();
    this.clearAdminRole();
    window.location.reload();
  }

  get isAuthorized(): boolean {
    return !!this.token;
  }
}

decorate(AuthStore, {
  token: observable,
  adminRole: observable,
  firstRoute: observable,
  globalProject: observable,

  setToken: action,
  clearToken: action,
  setAdminRole: action,
  clearAdminRole: action,
  logout: action,
  setfirstRoute: action,
  clearfirstRoute: action,
  setGlobalProject: action,
  clearGlobalProject: action,
  
  isAuthorized: computed,
});

export default new AuthStore();
