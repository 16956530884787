import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import styles from './GlobalMap.module.scss';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import ProjectSelect from '../../../components/UX/project-select/ProjectSelect';
import { API } from '../../../api';
import messageStore from '../../../stores/messageStore';
import settingsStore from '../../../stores/settingsStore';
import { MapPolylineCoords, WaypointsData } from '../../../models/general';
import { Map, GoogleApiWrapper, Polyline } from 'google-maps-react';
import { groupBy } from '../../../logic/group-by';
import { ROUTES } from '../../../parameters/routes';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import authStore from '../../../stores/authStore';

interface MapperProps {
  google: any;
  coords: MapPolylineCoords[];
  callback: (videoId: number) => void;
}

const Mapper = (props: MapperProps) => {
  const { coords, google, callback } = props;
  const grouped = groupBy(coords, (item: MapPolylineCoords) => item.videoID);

  const keys = Array.from(grouped.keys());

  const onPolylineClick = (videoId: number) => {
    callback(videoId);
  };

  const renderCollectedMarkers = (coords: MapPolylineCoords[], index: number) => {
    const random = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    // console.log(random);
    return (
      <Polyline
        key={index}
        path={coords}
        strokeColor={random}
        strokeOpacity={1}
        strokeWeight={6}
        onClick={() => onPolylineClick(coords[0].videoID)}
      />
    );
  };

  const renderPolylines = keys.map((key, index) => {
    const item = grouped.get(key);
    return renderCollectedMarkers(item, index);
  });

  return coords.length > 1 && google ? (
    <Map google={google} initialCenter={coords[0]} center={coords[0]}>
      {renderPolylines}
    </Map>
  ) : null;
};

const MapWrapper = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
})(Mapper);

interface GlobalMapProps extends RouteComponentProps {
  google: any;
}

const GlobalMap = (props: GlobalMapProps) => {
  const { t } = useTranslation();
  const { selectedProject } = settingsStore;
  const { adminRole, globalProject } = authStore;
  const [waypoints, setwaypoints] = useState<WaypointsData[]>([]);
  const [coords, setCoords] = useState<MapPolylineCoords[]>([]);
  const [dialog, setDialog] = React.useState(false);
  const [selectedVideo, setselectedVideo] = React.useState<number>();
  const useProject = useMemo(() => selectedProject ?? globalProject, [selectedProject, globalProject]);

  const  fetchData = (currentPage: number, waypoints: WaypointsData[]) => {
    API.getWaypoints({
      videoIDs: [],
      projectID: useProject ? useProject.toString() : '',
      offset: (currentPage - 1) * 10,
      limit: 10,
    })
      .then((response) => {
        if (!!response.data.waypoints) {
          console.log('waypoints', waypoints.length)
          const newPoints = [ ...waypoints, ...response.data.waypoints];
          setwaypoints(newPoints);
          const pageCount = response.data.videosNumber.videosNumber || 0;
          // if there is more pages, load more
          // manual lazy load
          if (currentPage < Math.ceil(pageCount / 10)) {
            fetchData(currentPage + 1, newPoints)
          } else {
            settingsStore.updateGlobalLoading(false);
          }
        } else {
          setwaypoints([]);
          settingsStore.updateGlobalLoading(false);
          messageStore.snackbar({
            message: t('Project does not have waypoints'),
            type: 'error',
          });
        }
      })
      .catch((err) => {
        setwaypoints([]);
        settingsStore.updateGlobalLoading(false);
        messageStore.snackbar({
          message: t('Failed fetching waypoints or no videos'),
          type: 'error',
        });
      })
  }

  const fetchWaypoints = useCallback(async () => {
    console.log('useProject', useProject);
    if (useProject) {
      settingsStore.updateGlobalLoading(true);
      fetchData(1, [])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useProject, t]);

  useEffect(() => {
    const coords: MapPolylineCoords[] = [];
    waypoints.length > 1 &&
      waypoints.map((point) =>
        coords.push({
          videoID: point.videoID,
          lat: point.latitude,
          lng: point.longitude,
        })
      );
    setCoords(coords);
  }, [waypoints]);

  const onCloseDialog = (event: boolean) => {
    setDialog(false);
    if (event) {
      if (selectedVideo) {
        window.open(ROUTES.createVideoUrl(selectedVideo), '_blank');
      }
    }
  };

  useEffect(() => {
    fetchWaypoints();
  }, [fetchWaypoints]);

  return (
    <SimpleLayout passedStyles={styles.root}>
      {adminRole === 'onProject' ? null : (
        <CarchupaPaper type="row" parentStyle={styles.paper}>
          <ProjectSelect></ProjectSelect>
        </CarchupaPaper>
      )}
      <CarchupaPaper type="row" parentStyle={styles.paper}>
        {!!useProject ? (
          <div className={styles.container}>
            <div className={styles.map}>
              <MapWrapper
                coords={coords}
                callback={(videoId: number) => {
                  setselectedVideo(videoId);
                  setDialog(true);
                }}
              />
            </div>
          </div>
        ) : (
          <span>Please select project</span>
        )}
      </CarchupaPaper>
      <CarchupaDialog open={dialog} handleClose={onCloseDialog} message={t('Do you want to open selected route video?')} title={t('Confirm')} />
    </SimpleLayout>
  );
};

export default inject('settingsStore', 'messageStore', 'authStore')(observer(GlobalMap));
