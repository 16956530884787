import { FormControl, Select, InputLabel, Button } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import settingsStore from '../../../stores/settingsStore';
import styles from './DateRangeSelect.module.scss';
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';

interface Props2 {}

// const DateRangePickerCustom = (props: Props2) => {
//   const [open, setOpen] = React.useState(false);
//   const [dateRange, setDateRange] = React.useState<DateRange>({});
//   const toggle = () => setOpen(!open);

//   return (
//     <DateRangePicker
//       open={open}
//       toggle={toggle}
//       onChange={(range) => setDateRange(range)}
//     />
//   );
// };

type Props = {};

export const ProjectMenuItem = React.forwardRef(({ open, toggle, setDateRange }: any, ref) => (
  <DateRangePicker
    open={open}
    toggle={toggle}
    wrapperClassName={styles.root}
    onChange={(range) => setDateRange(range)}
    // definedRanges={[
    //   {
    //     label: 'All',
    //     startDate: new Date(),
    //     endDate: new Date()
    //   }
    // ]}
  />
));

const DateRangeSelect = (props: Props) => {
  const { selectedPeriod } = settingsStore;
  const ref = React.createRef();
  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);

  const handleChange = (value: any) => {
    // console.log("value 2", value);
    settingsStore.updateSelectedPeriod(value);
  };

  return (
    <FormControl className={styles.formControl}>
      <InputLabel shrink id="project-select-label">
        Range
      </InputLabel>
      <Select
        labelId="project-select-label"
        id="project-select"
        open={open}
        //value={!!selectedPeriod ? selectedPeriod : ""}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        renderValue={(selected) => {
          if (!!selectedPeriod?.label) {
            return <em>{selectedPeriod?.label}</em>;
          } else if (!!selectedPeriod?.startDate && !!selectedPeriod?.endDate) {
            return (
              <em>
                {moment(selectedPeriod.startDate).format('YYYY-MM-DD')}&nbsp;-&nbsp;
                {moment(selectedPeriod.endDate).format('YYYY-MM-DD')}
              </em>
            );
          } else {
            return <em>All</em>;
          }
        }}
        displayEmpty
        ref={ref}
      >
        <ProjectMenuItem toggle={toggle} setDateRange={handleChange} open={open} />
        <Button className={styles.button} variant="contained" color="primary" type="button" onClick={() => handleChange(null)}>
          Clear
        </Button>
      </Select>
    </FormControl>
  );
};

export default inject('settingsStore')(observer(DateRangeSelect));
