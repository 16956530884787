import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './VideoList.module.scss';
import { inject, observer } from 'mobx-react';
import MaterialTable from 'material-table';
import { Header } from '../../../models/tables';
import { useTranslation } from 'react-i18next';
import CarchupaPaper from '../../UI/papper/CarchupaPaper';
import VideoItem from '../../UI/video-item/VideoItem';
import { Button, Card, Checkbox, createStyles, Dialog, FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
import videoStore from '../../../stores/videoStore';
import VideoActionSelect from '../video-action-select/VideoActionSelect';
import { VideoData } from '../../../models/video';
import { RouteComponentProps } from 'react-router-dom';
import settingsStore from '../../../stores/settingsStore';
import messageStore from '../../../stores/messageStore';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import { API } from '../../../api';
import authStore from '../../../stores/authStore';

interface Props extends RouteComponentProps {
  videoList: VideoData[];
  pageSizeOptions: number[];
}

const VideoList = (props: Props) => {
  const { videoList, pageSizeOptions } = props;
  const [videoRowItems, setVideoRowItems] = useState(3);
  const { adminRole } = authStore;
  const [upload, uploadPending] = useCallbackWithLoading(API.uploadGeojson);

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>({});

  const canSubmit = useMemo(() => {
    return !!data?.selectedFiles?.length;
  }, [data]);

  const { selectedVideos, selectedVideoTablePage } = videoStore;
  const { selectedRowCount } = settingsStore;

  const useStyles = makeStyles(() =>
    createStyles({
      videoItem: {
        width: `${100 / videoRowItems}%`,
      },
    })
  );

  const haveSelectedVideos = useMemo(() => !!selectedVideos.length, [selectedVideos.length]);

  const allVideosSelected = useMemo(() => selectedVideos.length === videoList.length, [selectedVideos.length, videoList.length]);

  const classes = useStyles();

  const headCells: Header[] = [
    {
      field: 'ID',
      title: t('ID'),
      sortable: true,
    },
    {
      field: 'name',
      title: t('Video Name'),
      sortable: true,
    },
    {
      field: 'createdAt',
      title: t('Created'),
      sortable: true,
    },
    {
      field: 'isFinished',
      title: t('Finished'),
      sortable: true,
      render: (rowData: VideoData) => (!!rowData.isFinished ? 'Yes' : 'No'),
    },
    {
      field: 'collectables.coinCount',
      title: 'C',
      sortable: true,
    },
    {
      field: 'collectables.strawberryCount',
      title: 'S',
      sortable: true,
    },
    {
      field: 'collectables.diamondCount',
      title: 'D',
      sortable: true,
    },
  ];

  const handleChangeAllVideos = (event: { target: { checked: React.SetStateAction<boolean> } }) => {
    console.log('event.target.checked', event.target.checked);
    if (event.target.checked) {
      videoStore.updateVideos(videoList.map((video) => video.ID));
    } else {
      videoStore.updateVideos([]);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const uploadGeojson = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!canSubmit) {
        return;
      } else {
        try {
          data.selectedFiles.map(async (file: any) => {
            const forSending = new FormData();

            forSending.append('geojsonFile', file);
            // canSubmit is checking that project is selected
            await upload(forSending)
              .then((res: any) => {
                messageStore.snackbar({
                  message: `${t('Upload successfull')}`,
                  type: 'success',
                });
                setOpen(false);
              })
              .catch((error: any) => {
                messageStore.snackbar({
                  message: `${t('Fail uploading to Database - ') + error?.message}`,
                  type: 'error',
                });
                setOpen(false);
                console.error('Fail uploading json', error);
              });

            setData({});
          });
        } catch (error) {
          messageStore.snackbar({
            message: `${t('Fail uploading coins')}`,
            type: 'error',
          });
          console.error('Fail uploading coins', error);
          setData({});
        }
      }
    },
    [canSubmit, data.selectedFiles, upload, t]
  );

  const handleDataChange = useCallback((event: any) => {
    const list = Array.from(event.target.files);
    setData({
      selectedFiles: list,
      loaded: 0,
    });
  }, []);

  const getChunks = useCallback(
    (array: any[]) => {
      // console.log('getChunks')
      const final = [];
      for (var i = 0; i < Math.ceil(array.length / videoRowItems); i++) {
        const chunks = array.slice(i * videoRowItems, i * videoRowItems + videoRowItems);
        const item: any = { items: chunks };
        final.push(item);
      }
      return final;
    },
    [videoRowItems]
  );

  useEffect(() => {
    setVideoRowItems(3);
  }, []);

  const rows = useMemo(() => {
    const chunks = getChunks(videoList);
    // console.log(chunks);
    return chunks;
  }, [getChunks, videoList]);

  // console.log(
  //   Math.ceil(rows.length / selectedRowCount) >= selectedVideoTablePage,
  //   Math.ceil(rows.length / selectedRowCount),
  //   selectedVideoTablePage
  // );

  return (
    <>
      <MaterialTable
        columns={headCells}
        title={t('Videos')}
        data={rows}
        options={{
          search: false,
          padding: 'default',
          pageSize: selectedRowCount,
          initialPage: Math.ceil(rows.length / selectedRowCount) >= selectedVideoTablePage ? selectedVideoTablePage : 0,
          pageSizeOptions: pageSizeOptions,
        }}
        components={{
          Toolbar: (materialprops: any) =>
            haveSelectedVideos ? (
              <div className={`${styles.selectedVideos}`}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Typography variant="h6" component="h2">
                    {selectedVideos.length} row(s) selected Search
                  </Typography>

                  <VideoActionSelect />
                </Grid>
              </div>
            ) : adminRole === 'superadmin' ? (
              <Grid container direction="row" justify="flex-end" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  <div className={styles.actionButtons}>
                    <ExploreIcon />
                    <span>Upload GEOJSON</span>
                  </div>
                </Button>
              </Grid>
            ) : null,
          Header: (materialprops: any) => (
            <tbody>
              <tr>
                <th colSpan={videoRowItems} className={`${styles.customHeader}`}>
                  {!!rows.length ? (
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allVideosSelected}
                            onChange={handleChangeAllVideos}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Select All"
                      />
                    </Grid>
                  ) : null}
                </th>
              </tr>
            </tbody>
          ),
          Body: (materialprops: any) => {
            // console.log(" Bodyprops", JSON.stringify(props.renderData));
            return (
              <tbody>
                {materialprops.renderData.map((videoList: any) => {
                  return (
                    <tr className={styles.videoRow} key={Math.random()}>
                      {videoList.items.map((video: VideoData) => (
                        <td className={`${styles.videoItem} ${classes.videoItem}`} key={video.ID}>
                          <VideoItem video={video} {...props}></VideoItem>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            );
          },
          Container: (props: any) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
        }}
        onChangeRowsPerPage={(rows) => {
          settingsStore.updateSelectedRowCount(rows);
        }}
        onChangePage={(rows) => {
          videoStore.updateSelectedVideoTablePage(rows);
        }}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Grid container direction="column" justify="center" alignItems="center" className={styles.modal}>
          <form onSubmit={uploadGeojson}>
            <Card elevation={0}>
              <Grid container direction="column" justify="center" alignItems="center">
                <input multiple type="file" name="file" onChange={handleDataChange} />
                <br />
                <Button className={styles.inputs} type="submit" variant="contained" disabled={!canSubmit || uploadPending}>
                  {t('Upload Geojson')}
                </Button>
              </Grid>
            </Card>
          </form>
        </Grid>
      </Dialog>
    </>
  );
};

export default inject('videoStore', 'settingsStore', 'messageStore', 'authStore')(observer(VideoList));
