import { observable, action, decorate, computed } from 'mobx';
import { VideoRoadUserPosition } from '../models/video';

export class PlayerStateStore {
  playerProgressState: number = 0;
  playerSeekState: number | null = null;
  userPosition: VideoRoadUserPosition = {};

  updatePlayerProgressState(value: number) {
    this.playerProgressState = value;
  }
  updatePlayerSeekState(value: number) {
    this.playerSeekState = value;
  }
  updateUserPosition(value: VideoRoadUserPosition) {
    this.userPosition = value;
  }


  get getPlayerProgressState(): number {
    return this.playerProgressState;
  }
  get getPlayerSeekState(): number | null {
    return this.playerSeekState;
  }
  get getUserPosition(): VideoRoadUserPosition {
    return this.userPosition;
  }
}

decorate(PlayerStateStore, {
  playerProgressState: observable,
  playerSeekState: observable,
  userPosition: observable,

  updatePlayerProgressState: action,
  updatePlayerSeekState: action,
  updateUserPosition: action,

  getPlayerProgressState: computed,
  getPlayerSeekState: computed,
  getUserPosition: computed,
});

export default new PlayerStateStore();
