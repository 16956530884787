import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authStore from '../../../stores/authStore';

interface AdminRouteProps {
  path: any;
  exact?: boolean;
  component: any;
}

const AdminRoute = (props: AdminRouteProps) => {
  const path = props.path;
  const component = props.component;

  return authStore.adminRole === 'superadmin' || authStore.adminRole === 'admin' ? (
    <Route path={path} exact={props.exact ? props.exact : false} component={component} />
  ) : (
    <Redirect to={{ pathname: '/videos' }} />
  );
};

export default AdminRoute;
