import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import ReactPlayer from 'react-player';
import styles from './Player.module.scss';
import playerStateStore from '../../../stores/playerStateStore';

interface Props {
  videoSrc: string;
  bigPlayer?: boolean;
}

export interface PlayerProgressState {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
}

const VideoPlayer = (props: Props) => {
  const { userPosition, playerSeekState } = playerStateStore;
  const playerRef = useRef<ReactPlayer | null>(null);
  const handleOnProgress = (state: PlayerProgressState) => {
    playerStateStore.updatePlayerProgressState(state.playedSeconds);
  };

  useEffect(() => {
    if (playerRef.current && playerSeekState) {
      playerRef.current.seekTo(playerSeekState, 'seconds');
    }
  }, [playerSeekState]);

  useEffect(() => {
    playerStateStore.updatePlayerProgressState(0);
  }, []);

  const { videoSrc, bigPlayer } = props;
  return (
    <div className={styles.container}>
      <div className={!!bigPlayer ? styles.videoBigPlayer : styles.videoPlayer}>
        <ReactPlayer
          ref={playerRef}
          url={videoSrc}
          className="react-player"
          light={bigPlayer ? false : true}
          playing={false}
          controls
          width="100%"
          height="100%"
          onProgress={(state) => handleOnProgress(state)}
        />
      </div>
      {bigPlayer && (
        <div className={styles.videoOverlay}>
          <div className={styles.roadInfo}>
            <span> {userPosition.road ? userPosition.road : 'Road name missing'}</span>
          </div>
          <div className={styles.roadId}>
            <span> {userPosition.roadid ? userPosition.roadid : 'Road ID missing'}</span>
          </div>
          <div className={styles.chainage}>
            <span> {userPosition.chainage ? userPosition.chainage : 'Chainage missing'}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default inject('playerStateStore')(observer(VideoPlayer));
