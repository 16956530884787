/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { useTranslation } from 'react-i18next';

import blueberry from '../../../assets/blueberry.png';
import cent from '../../../assets/cent.png';
import cherry from '../../../assets/cherry.png';
import diamond from '../../../assets/diamond_256x256.png';
import lemon from '../../../assets/lemon.png';
import strawberry from '../../../assets/strawberry_256x256.png';

import blueberryCollected from '../../../assets/blueberry-collected.png';
import centCollected from '../../../assets/cent-collected.png';
import cherryCollected from '../../../assets/cherry-collected.png';
import diamondCollected from '../../../assets/diamond_256x256-collected.png';
import lemonCollected from '../../../assets/lemon-collected.png';
import strawberryCollected from '../../../assets/strawberry_256x256-collected.png';

import { API } from '../../../api';
import settingsStore from '../../../stores/settingsStore';
import messageStore from '../../../stores/messageStore';

import styles from './Map.module.scss';

const MapContainer = (props) => {
  const { google, projectID } = props;
  const [loading, setloading] = useState(true);
  const [activeCollectibles, setActiveCollectibles] = useState([]);
  const [collectedCollectibles, setCollectedCollectibles] = useState([]);
  const { t } = useTranslation();

  const loadCollectibles = useCallback(async () => {
    setloading(true);
    settingsStore.updateGlobalLoading(true);

    const response = await API.getProjectCollectables(projectID);
    if (response) {
      setActiveCollectibles(response.data.availableCollectablesList);
      setCollectedCollectibles(response.data.collectedCollectablesList);

      setloading(false);
      settingsStore.updateGlobalLoading(false);

      messageStore.snackbar({
        message: t('Projects loaded'),
        type: 'success',
      });
    } else {
      setloading(false);
      settingsStore.updateGlobalLoading(false);
      messageStore.snackbar({
        message: t('Failed fetching projects'),
        type: 'error',
      });
    }
  }, [projectID, t]);

  const renderActiveMarkers = collectedCollectibles.map((marker, index) => {
    return (
      <Marker
        key={index}
        position={{ lat: Number(marker.lat), lng: Number(marker.lon) }}
        icon={{
          url:
            (marker.collectableType === 1 && centCollected) ||
            (marker.collectableType === 2 && blueberryCollected) ||
            (marker.collectableType === 3 && cherryCollected) ||
            (marker.collectableType === 4 && lemonCollected) ||
            (marker.collectableType === 5 && strawberryCollected) ||
            (marker.collectableType === 6 && diamondCollected),

          scaledSize: new google.maps.Size(20, 20),
        }}
      />
    );
  });

  const renderCollectedMarkers = activeCollectibles.map((marker, index) => {
    return (
      <Marker
        key={index}
        position={{ lat: Number(marker.lat), lng: Number(marker.lon) }}
        icon={{
          url:
            (marker.collectableType === 1 && cent) ||
            (marker.collectableType === 2 && blueberry) ||
            (marker.collectableType === 3 && cherry) ||
            (marker.collectableType === 4 && lemon) ||
            (marker.collectableType === 5 && strawberry) ||
            (marker.collectableType === 6 && diamond),
          scaledSize: new google.maps.Size(20, 20),
        }}
      />
    );
  });

  useEffect(() => {
    loadCollectibles();
  }, []);

  return (
    <div className={styles.container}>
      <div>
        {!loading ? (
          <Map
            google={google}
            zoom={14}
            initialCenter={{
              lat: !!activeCollectibles.length ? activeCollectibles[0].lat : !!collectedCollectibles.length ? collectedCollectibles[0].lat : '',
              lng: !!activeCollectibles.length ? activeCollectibles[0].lon : !!collectedCollectibles.length ? collectedCollectibles[0].lon : '',
            }}
          >
            {renderActiveMarkers}
            {renderCollectedMarkers}
          </Map>
        ) : null}
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(MapContainer);

// {projects.collectedCollectablesList.map((marker) => {
//   return <Marker key={marker.InfoWindow} position={{ lat: '45.810200950000', lng: '15.987945080000' }} />;
// })}

/* {console.log(projects.collectedCollectablesList.map((marker) => marker))} */
