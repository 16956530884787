export const ROUTES = {
  passwordReset: `${process.env.PUBLIC_URL}/passwordReset/:token`,
  passwordRequest: `${process.env.PUBLIC_URL}/passwordRequest`,
  login: `${process.env.PUBLIC_URL}/login`,
  home: `${process.env.PUBLIC_URL}/`,
  sessions: `${process.env.PUBLIC_URL}/sessions`,
  userSessions: `${process.env.PUBLIC_URL}/user-sessions/:userId`,
  createUserSessionsUrl(userId: number) {
    return `${process.env.PUBLIC_URL}/user-sessions/${userId}`;
  },
  videos: `${process.env.PUBLIC_URL}/videos`,
  sessionVideos: `${process.env.PUBLIC_URL}/session-videos/:sessionId`,
  createVideosUrl(sessionId: number) {
    return `${process.env.PUBLIC_URL}/session-videos/${sessionId}`;
  },
  video: `${process.env.PUBLIC_URL}/video/:videoId`,
  createVideoUrl(videoId: number) {
    return `${process.env.PUBLIC_URL}/video/${videoId}`;
  },
  users: `${process.env.PUBLIC_URL}/users`,
  user: `${process.env.PUBLIC_URL}/profile/:userId`,
  userPayments: `${process.env.PUBLIC_URL}/userPayments/:userId`,
  createUsersPaymentsUrl(userId: number) {
    return `${process.env.PUBLIC_URL}/userPayments/${userId}`;
  },
  createUsersInfoUrl(userId: number) {
    return `${process.env.PUBLIC_URL}/profile/${userId}`;
  },
  projects: `${process.env.PUBLIC_URL}/projects`,
  project: `${process.env.PUBLIC_URL}/project/:projectId`,
  createProjectsInfoUrl(projectId: number) {
    return `${process.env.PUBLIC_URL}/project/${projectId}`;
  },
  addProject: `${process.env.PUBLIC_URL}/add-project`,
  leaderboard: `${process.env.PUBLIC_URL}/leaderboard`,
  payments: `${process.env.PUBLIC_URL}/payments`,
  coinUpload: `${process.env.PUBLIC_URL}/coin-upload`,
  overview: `${process.env.PUBLIC_URL}/overview`,
  admins: `${process.env.PUBLIC_URL}/admins`,
  notifications: `${process.env.PUBLIC_URL}/notifications`,
  globalmap: `${process.env.PUBLIC_URL}/global-map`,
};
